import React from "react";
import './BuySell.css';

const BuySell = () => {
  // Get today's date and format it as MM/DD/YYYY
  const today = new Date();
  const formattedDate = `${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getDate().toString().padStart(2, '0')}/${today.getFullYear()}`;

  return (
    <div className="BSMCont">
      <div className="BuySellCont">
        <div>
          <h2 className="BSMHeading">
            Buying🏠
          </h2>
          <strong>
            There’s a lot to know when it comes to buying a home.
          </strong>
          <p>Understanding the details of the local community, identifying the size of<br className="BrDesk" /> home, style of home and setting, and of course, organizing a budget—<br className="BrDesk" />these are all important details to consider. Whether you are a first time<br className="BrDesk" /> buyer, looking for a bigger house or downsizing, I’m here to help you every<br className="BrDesk" /> step of the way. </p>
        </div>
        <div>
          <h2 className="BSMHeading">
            💵SELLING
          </h2>
          <strong>
            I help you prepare, price and market your property.
          </strong>
          <p>I work hard for my clients and guarantee quality service and<br className="BrDesk" /> professionalism. You can count on me to be transparent and honest every<br className="BrDesk" />step of the process. I’ll show your space to qualified buyers and skillfully<br className="BrDesk" /> negotiate on your behalf to secure the best price and the quickest closing. </p>
        </div>
      </div>
      <div className="Mortgage">
        <h2 className="BSMHeading">
          Mortgage Rates
        </h2>
        <p>
          Updated {formattedDate}
        </p>
        <div className="StaticsMain">
          <div>
            <strong>30 Year Fixed</strong>
            <h1>6.825%</h1>
          </div>
          <div>
            <strong>15 Year Fixed</strong>
            <h1>6.25%</h1>
          </div>
          <div>
            <strong>7/6 ARM</strong>
            <h1>7.5%</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuySell;
